import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ImageViewer from "react-simple-image-viewer";
import _ from "lodash";
import { SliderWrapper, SliderMain, SliderThumbs, SliderItem } from "./styled";
import { connect } from "react-redux";

const SliderWithThumbs = ({ items, s }) => {
	const sliderMain = useRef(null);

	const [isMobile, setIsMobile] = useState(false);
	const [imageViewerImages, setImageViewerImages] = useState([]);
	const [imageViewerIndex, setImageViewerIndex] = useState(0);
	const [imageViewerShow, setImageViewerShow] = useState(false);

	const imageViewerOpen = (idx) => {
		setImageViewerIndex(idx);
		setImageViewerShow(true);
	};

	const imageViewerClose = () => {
		setImageViewerShow(false);
	};

	useEffect(() => {
		if (items) {
			const images = [];
			_.forEach(items, (imagen) => {
				images.push(imagen.url);
			});
			setImageViewerImages(images);
		} else {
			setImageViewerImages([]);
			setImageViewerShow(false);
		}
	}, [items]);

	useEffect(() => {
		const resiseListener = () => {
			if (window.innerWidth < 991) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};
		resiseListener();
		window.addEventListener("resize", resiseListener);
		return () => {
			window.removeEventListener("resize", resiseListener);
		};
	}, []);

	return (
		<SliderWrapper>
			{items.length > 1 && !isMobile && (
				<SliderThumbs>
					<Slider
						infinite={false}
						slidesToShow={items.length < 5 ? items.length : 5}
						// slidesToShow={5}
						slidesToScroll={1}
						slidesPerRow={1}
						vertical={true}
						verticalSwiping={true}
					>
						{items.map((imagen, idx) => {
							return (
								<SliderItem key={idx}>
									<button onClick={() => sliderMain.current?.slickGoTo(idx)}>
										<img src={imagen.url} />
									</button>
								</SliderItem>
							);
						})}
					</Slider>
				</SliderThumbs>
			)}
			<SliderMain full={isMobile}>
				<Slider
					ref={sliderMain}
					dots={false}
					arrows={false}
					infinite={false}
					slidesToShow={1}
					slidesToScroll={1}
					slidesPerRow={1}
				>
					{items.map((imagen, idx) => {
						return (
							<SliderItem key={idx}>
								<button onClick={() => imageViewerOpen(idx)}>
									<img src={imagen.url} />
								</button>
							</SliderItem>
						);
					})}
				</Slider>
				{items.length > 1 && isMobile && (
					<div className="slider-arrows">
						<span className="arrow left" onClick={() => sliderMain.current?.slickPrev()} />
						<span className="arrow right" onClick={() => sliderMain.current?.slickNext()} />
					</div>
				)}
			</SliderMain>

			{imageViewerShow && (
				<ImageViewer
					src={imageViewerImages}
					currentIndex={imageViewerIndex}
					onClose={imageViewerClose}
					backgroundStyle={{ zIndex: 9999, backgroundColor: "rgba(0, 0, 0, .9)" }}
				/>
			)}
		</SliderWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(SliderWithThumbs);
