import React, { useEffect, useRef } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import { appRedirect, appUIPopupTypeformShow, appUIPopupMapShow } from "../redux/app/actions";
import { anuncioGet, anuncioReset, anuncioHotelesGet } from "../redux/anuncios/actions";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import Slider from "react-slick";
import { ReactSVG } from "react-svg";
import SliderWithThumbs from "../components/common/SliderWithThumbs";
import IconPaddle from "../assets/gfx/svgicons/icon-size-paddle.svg";
import IconLong from "../assets/gfx/svgicons/icon-size-long.svg";
import IconBody from "../assets/gfx/svgicons/icon-size-body.svg";
import IconShort from "../assets/gfx/svgicons/icon-size-short.svg";
import IconMeasure from "../assets/gfx/svgicons/icon-measure.svg";
import IconLocation from "../assets/gfx/location.svg";
import MapCustom from "../components/common/MapCustom";
import SliderCustom from "../components/common/SliderCustom";
import SidebarButtonsBlock from "../components/SidebarButtonsBlock";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const Anuncio = ({
	appUIPopupTypeformShow,
	appUIPopupMapShow,
	anuncio,
	anuncioHoteles,
	appRedirect,
	anuncioGet,
	anuncioReset,
	anuncioHotelesGet,
	location,
	appIsMobile,
	s,
}) => {
	const { fase } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						fase
					}
				}
			}
		`
	).site.siteMetadata;

	const intl = useIntl();

	var sliderHotels = useRef(null);

	useEffect(() => {
		anuncioReset();
	}, []);

	useEffect(() => {
		const pathParts = location.pathname.split("/");
		const id = parseInt(pathParts[pathParts.length - 1]);
		if (!id) {
			appRedirect({ to: "/" });
		} else {
			anuncioGet(intl.locale, id);
		}
	}, []);

	useEffect(() => {
		if (anuncio && anuncio.lat && anuncio.long) {
			anuncioHotelesGet(intl.locale, {
				mode: "geo",
				lat: anuncio.lat,
				long: anuncio.long,
				orden: "distancia",
				page: 1,
				limit: 10,
			});
		}
	}, [anuncio]);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	const getSizeIcon = (size) => {
		switch (size) {
			case "Long Paddle":
				return <img src={IconPaddle} alt={size} />;
			case "Funboard Evolutiva":
				return <img src={IconLong} alt={size} />;
			case "Bodyboard":
				return <img src={IconBody} alt={size} />;
			case "Short":
				return <img src={IconShort} alt={size} />;
			default:
				return "";
		}
	};

	return (
		<>
			<BodyClassName className="two-col-page">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Ver anuncio" />
					<main className="page">
						{anuncio && (
							<>
								<article className="two-col-wrapper">
									{/* MAIN */}
									<div className="two-col-main">
										{/* Slider IMG's */}
										<section className="slider slider-boardpost-page">
											{!anuncio.imagenes ||
												(anuncio.imagenes.length === 0 && (
													<div className="slider-wrapper-img">
														<img src="/img/anuncio-sin-imagenes.png" />
													</div>
												))}
											{anuncio.imagenes && anuncio.imagenes.length > 0 && (
												<SliderWithThumbs items={anuncio.imagenes} />
											)}
										</section>
										{/* /Slider IMG's */}

										{appIsMobile && (
											<div className="sidebar">
												<SidebarButtonsBlock anuncio={anuncio} />
											</div>
										)}

										{/* DESCRIPTION */}
										<section className="preview-description box white-bg with-padding-left">
											<div className="description-block">
												<h3>
													<strong dangerouslySetInnerHTML={{ __html: anuncio.title }} />
												</h3>
												<p dangerouslySetInnerHTML={{ __html: anuncio.descripcion }} />
											</div>

											<div className="description-block">
												<div className="two-columns-block">
													<div className="col">
														<h3>
															<strong>Tipo de tabla</strong>
														</h3>
														<div className="icon-text">
															<span>{anuncio.tabla && getSizeIcon(anuncio.tabla.title)}</span>
															<p>{anuncio.tabla ? anuncio.tabla.title : anuncio.tabla_otra}</p>
														</div>
													</div>
													{anuncio.medidas && (
														<div className="col">
															<h3>
																<strong>Medidas</strong>
															</h3>
															<div className="icon-text">
																<span>
																	<img src={IconMeasure} alt={anuncio.medidas} />
																</span>
																<p>{anuncio.medidas}</p>
															</div>
														</div>
													)}
												</div>
											</div>

											{anuncio.punto_encuentro && (
												<div className="description-block">
													<h3>
														<strong>Puntos de encuentro</strong>
													</h3>
													<p>Nos podemos encontrar para dejarte mi tabla en:</p>

													<div className="two-columns-block">
														{/*{anuncio.punto_encuentro.map((punto, index) => {*/}
														{/*	return (*/}
														<div className="col">
															<button
																className="button border-black with-icon"
																onClick={() =>
																	appUIPopupMapShow({ lat: anuncio.lat, long: anuncio.long })
																}
															>
																<p>{anuncio.punto_encuentro}</p>
																<span>
																	<ReactSVG src={IconLocation} />
																</span>
															</button>
														</div>
														{/*	);*/}
														{/*})}*/}
													</div>
												</div>
											)}

											{/*{anuncio.disponibilidad && (*/}
											<div className="description-block">
												<h3>
													<strong>Disponibilidad habitual</strong>
												</h3>
												<p>Contacta conmigo para ver si podré alquilar la tabla los días que necesitas.</p>
												{/* <ul>
													<li>Fines de semana por la mañanas</li>
												</ul> */}
											</div>
											{/*)}*/}

											<div className="description-block">
												<h3>
													<strong>Cosas que deberias saber</strong>
												</h3>

												<div className="two-columns-block">
													<div className="col">
														<div className="col-text">
															<p>Garantía y seguridad</p>
															<p className="small ">
																Cuida tu tabla como si fuera tuya. Intenta no hacerle ningún toque, ten
																cuidado y devuelvela como la alquilaste.
															</p>
														</div>
														<Link className="defaultLink" to={`/faqs/`} target="_blank" rel="noreferrer">
															Ver más
														</Link>
													</div>

													<div className="col">
														<div className="col-text">
															<p>Política de cancelación</p>
															<p className="small ">
																Si has quedado con el surfista para alquilar su tabla y finalmente no
																puedes, avisale por lo menos con 24 horas de antelación.
															</p>
														</div>
														<Link className="defaultLink" to={`/faqs/`} target="_blank" rel="noreferrer">
															Ver más
														</Link>
													</div>
												</div>
											</div>

											<div className="description-block noBorder">
												<h3>
													<strong>¿Podemos ayudarte?</strong>
												</h3>
												<p>
													Si tienes cualquier duda, escríbenos y te contestaremos en un plazo máximo de 48
													horas.
												</p>

												{/* <button
													className="button button-inline border-black"
													onClick={() => appUIPopupTypeformShow("consultar")}
												>
													Enviar consulta
												</button> */}
											</div>
										</section>
										{/* /DESCRIPTION */}

										{/* MAP */}
										{fase >= 2 && (
											<section className="preview-map">
												<iframe
													title="Mapa"
													style={{ width: "100%", border: 0 }}
													src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.432694752779!2d-2.172660340822819!3d43.284259514415815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd51c84472ad6a39%3A0x8f6e0327e3ebdc7!2sZarautz!5e0!3m2!1ses-419!2ses!4v1586798872053!5m2!1ses-419!2ses"
													width="600"
													height="225"
													frameBorder="0"
													allowFullScreen=""
													aria-hidden="false"
												></iframe>
											</section>
										)}
										{/* /MAP */}

										{/* LENDER PROFILE FOR MOBILE */}
										{anuncio.usuario && (
											<section className="lender-profile blue-bg box hide-lg">
												{anuncio.usuario && anuncio.usuario.imagen && (
													<img
														className="profile-img"
														src={anuncio.usuario.imagen.url}
														alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
													/>
												)}
												{anuncio.usuario && !anuncio.usuario.imagen && (
													<img
														className="profile-img"
														src="/img/user.svg"
														alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
													/>
												)}
												<div className="right">
													<h5 className="red-text prequel-font">Lender</h5>
													<h2 className="name white-text saint-font">
														{anuncio.usuario.nombre} {anuncio.usuario.apellido}
													</h2>
												</div>
												<p className="likes white-text">{anuncio.usuario.descripcion}</p>
											</section>
										)}
										{/* /LENDER PROFILE FOR MOBILE */}

										{fase >= 2 && (
											<section className="reviews box white-bg">
												<h1 className="black-text-03 prequel-font">REVIEWS</h1>
												<strong className="rate">
													<img src="/img/rate.svg" alt={``} />
													4,5<span>(21)</span>
												</strong>
												<div className="progress-bar">
													<ul>
														<li>
															<p>Puntualidad</p>
															<span className="content-holder lightgray-bg-03">
																<span className="content black-bg-03" style={{ width: "50%" }}></span>
															</span>
															<small>2,8</small>
														</li>
														<li>
															<p>Veracidad</p>
															<span className="content-holder lightgray-bg-03">
																<span className="content black-bg-03" style={{ width: "75%" }}></span>
															</span>
															<small>3,8</small>
														</li>
														<li>
															<p>Comunicación</p>
															<span className="content-holder lightgray-bg-03">
																<span className="content black-bg-03" style={{ width: "100%" }}></span>
															</span>
															<small>5</small>
														</li>
													</ul>
												</div>
												<div className="testimonials">
													<div className="wrapper">
														<img
															className="testimonial-img"
															src="https://placeimg.com/100/100/animals"
															alt={``}
														/>
														<div className="wrapper-text">
															<h5 className="prequel-font black-text-01">Yi Hanying</h5>
															<small className="date black-text-03">3 Feb 2020</small>
														</div>
													</div>
													<p className="text">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
														incididunt ut labore et dolore magna aliqua ut enim ad minim veniam, quis
														nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
													</p>
													<p className="no-reviews lightgray-text-02">No hay más reviews</p>
												</div>
											</section>
										)}
									</div>
									{/* MAIN */}

									{/* SIDEBAR */}
									{!appIsMobile && (
										<div className="two-col-sidebar">
											{/* Bloque fijo debajo del menu nav header */}
											<article className="sidebar">
												{/* LENDER PROFILE FOR DESKTOP */}
												<div className="sidebar-profile show-lg">
													<div className="sidebar-profile-img">
														{anuncio.usuario && anuncio.usuario.imagen && (
															<img
																className="profile-img"
																src={anuncio.usuario.imagen.url}
																alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
															/>
														)}
														{anuncio.usuario && !anuncio.usuario.imagen && (
															<img
																className="profile-img"
																src="/img/user.svg"
																alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
															/>
														)}
														<h2 className="name white-text saint-font">
															{anuncio.usuario &&
																`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
														</h2>
													</div>
													<p className="likes white-text">
														{anuncio.usuario && anuncio.usuario.descripcion}
													</p>
												</div>
												{/* /LENDER PROFILE FOR DESKTOP */}

												{anuncio.lat && anuncio.long && anuncio && (
													<div className="sidebar-map">
														<MapCustom lat={anuncio.lat} long={anuncio.long} />
													</div>
												)}

												<SidebarButtonsBlock anuncio={anuncio} />
											</article>
											{/* /Bloque fijo */}
										</div>
									)}
									{/* SIDEBAR */}
								</article>

								{/* TABLAS RELACIONADAS */}
								{anuncio?.relacionados && (
									<SliderCustom
										title="Tablas Relacionadas"
										linkText="Ver todas"
										linkUrl="/anuncios"
										items={anuncio.relacionados}
										typeContent="board"
									/>
								)}
								{/* TABLAS RELACIONADAS */}

								{/* HOTELES */}
								{anuncioHoteles && anuncioHoteles.length > 0 && (
									<SliderCustom title="Hoteles/Experiencias" items={anuncioHoteles} typeContent="hotel" />
								)}
								{/* HOTELES */}

								{/* HOTELES OTRO LAYOUT */}
								{fase >= 2 && anuncioHoteles && anuncioHoteles.length > 0 && (
									<>
										<section className="hotels box white-bg">
											<div className="wrapper">
												<h4 className="prequel-font black-text-01">HOTELES / EXPERIENCIAS</h4>
												<div className="slider slider-hotels">
													<Slider
														ref={(c) => (sliderHotels = c)}
														speed={500}
														infinite={false}
														arrows={false}
														slidesToShow={3}
														slidesToScroll={1}
														adaptiveHeight={true}
														responsive={[
															{
																breakpoint: 1199,
																settings: {
																	slidesToShow: 2,
																	slidesToScroll: 2,
																},
															},
															{
																breakpoint: 767,
																settings: {
																	slidesToShow: 1,
																	slidesToScroll: 1,
																},
															},
														]}
													>
														{anuncioHoteles.map((hotel) => {
															return (
																<Link
																	to={`/hoteles/${hotel.name}`}
																	className="slider-hotels-item"
																	key={hotel.id}
																>
																	<div className="wrapper">
																		<div
																			className="slider-item-hotels-wrapper"
																			style={{
																				backgroundImage: `url('${hotel.imagen_header_mobile.url}')`,
																			}}
																		/>
																		<div className="slider-hotels-details black-bg-01">
																			<p
																				className="hotel-title white-text"
																				dangerouslySetInnerHTML={{ __html: hotel.title }}
																			/>
																			<p
																				className="hotel-description lightgray-text-08"
																				dangerouslySetInnerHTML={{ __html: hotel.excerpt }}
																			/>
																		</div>
																	</div>
																</Link>
															);
														})}
													</Slider>
													<div className="slider-arrows">
														<span className="arrow left" onClick={() => sliderHotels.slickPrev()}></span>
														<span className="arrow right" onClick={() => sliderHotels.slickNext()}></span>
													</div>
												</div>
											</div>
										</section>
										<article className="hotels box white-bg">
											<p style={{ textAlign: "center" }}>
												¿Tienes un hotel y te gustaría incluirlo en esta sección gratis? Escríbenos a{" "}
												<a href="mailto:hello@shareyourboard.app">hello@shareyourboard.app</a>.
											</p>
											<br />
											<br />
											<br />
										</article>
									</>
								)}
								{/* HOTELES OTRO LAYOUT */}
							</>
						)}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { anuncio, anuncioHoteles } = state.anuncios;
	const { isMobile: appIsMobile } = state.app;
	return {
		anuncio,
		anuncioHoteles,
		appIsMobile,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupTypeformShow,
	appUIPopupMapShow,
	appRedirect,
	anuncioGet,
	anuncioReset,
	anuncioHotelesGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Anuncio);
