import styled from "styled-components";

export const SliderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const SliderItem = styled.div`
	height: 100%;
	button {
		border: none;
		padding: 0;
		display: block;
		width: 100%;
		height: 100%;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
`;

export const SliderThumbs = styled.div`
	max-width: 16%;
	flex: 0 0 16%;
	max-height: 100%;
	.slick-slider {
		height: 100%;
		.slick-list {
			margin-top: -5px;
			margin-bottom: -5px;
			height: 100%;
			.slick-track {
				height: 100% !important;
				min-height: 100%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				.slick-slide {
					position: relative;
					overflow: hidden;
					&:before {
						content: "";
						display: block;
						padding-top: 120%;
					}
					> div {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
					}
				}
			}
		}
	}
	${SliderItem} {
		display: block !important;
		overflow: hidden;
		line-height: 0;
		padding: 5px 0;
		button {
			cursor: pointer;
		}
	}
`;

export const SliderMain = styled.div`
	margin-left: auto;
	position: relative;
	max-width: ${(props) => (props.full ? "100%" : "calc(84% - 50px)")};
	flex: 0 0 ${(props) => (props.full ? "100%" : "calc(84% - 50px)")};
	&:before {
		content: "";
		display: block;
		padding-top: 120%;
	}
	.slick-slider {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		.slick-list {
			height: 100% !important;
			.slick-track {
				height: 100%;
				.slick-slide {
					height: 100%;
					> div {
						height: 100%;
					}
				}
			}
		}
	}
	.slider-arrows {
		width: 95%;
		left: 2.5%;
		//top: 43%;
	}

	${SliderItem} {
		button {
			cursor: zoom-in;
		}
	}
`;
